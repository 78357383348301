import { default as demonwjKKQqq2zMeta } from "/home/runner/work/goscope.net/goscope.net/pages/goroutines/demo.vue?macro=true";
import { default as indexcRaFxp7TZyMeta } from "/home/runner/work/goscope.net/goscope.net/pages/goroutines/index.vue?macro=true";
import { default as indext9FHMOtTU5Meta } from "/home/runner/work/goscope.net/goscope.net/pages/index.vue?macro=true";
import { default as privacy_45policyNJpDt6MNqFMeta } from "/home/runner/work/goscope.net/goscope.net/pages/privacy-policy.vue?macro=true";
import { default as termsqzAk1doUZAMeta } from "/home/runner/work/goscope.net/goscope.net/pages/terms.vue?macro=true";
export default [
  {
    name: "goroutines-demo",
    path: "/goroutines/demo",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/goroutines/demo.vue").then(m => m.default || m)
  },
  {
    name: "goroutines",
    path: "/goroutines",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/goroutines/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/goscope.net/goscope.net/pages/terms.vue").then(m => m.default || m)
  }
]